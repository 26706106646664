//保存基础字体大小
let originalFontSize = window.getComputedStyle(document.documentElement).fontSize;
// 设置 rem 函数
function setRem() {
  if (document.documentElement.clientWidth < 825){
    // 设置页面根节点字体大小
    document.documentElement.style.fontSize = 14 + 'px'
  }
  else{
    //恢复基础大小
    document.documentElement.style.fontSize = originalFontSize;
  }
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function() {
  setRem()
}

// // 懒得改引用了直接写罢
// let a;
// let b;
// // 设置网页样式的key
// if (document.documentElement.clientWidth < 840){
//   a = 'PHONE'
//   b = 'PHONE'
// }
// else{
//   a = 'PC'
//   b = 'PC'
// }
// // 更改网页样式
// function setRem() {
//   if (document.documentElement.clientWidth < 840){
//     a = 'PHONE'
//   }
//   else{
//     a = 'PC'
//   }
// }
// // 改变窗口大小时重新设置参数
// window.onresize = function() {
//   setRem()
//   if (a != b){
//     location.reload()
//     console.log('页面刷新')
//     b = a
//   } 
// }
<template>
  <div>
    <div class="header">
      <div class="appear_1"><NavBar></NavBar></div>
      <div class="appear_2"><NavBar_mini></NavBar_mini></div>
      <div class="title">
        <div>{{ $t("message.AboutUs") }}</div>
      </div>
    </div>
    <div style="margin: 50px;"></div><!-- 空 -->
    <div class="appear_1"><Card>
      <template v-slot:title> 公司介绍 </template>
      <template v-slot:body>
        <div class="CompanyProfile">
          <div class="head">上海奕盛软件有限公司</div>
          <div class="foot">
            <img src="@/assets/img/juxing18.png" />
            <div class="right">
              <div class="tit">卓越创新</div>
              <p class="twofloor">
                自2000年创建以来,以坚韧的努力和追求卓越的精神不断创新，致力于为中国地区的制造商提供完整的企业管理及IT解决方案帮助打造高效企业迎接全球化发展带来的挑战，并以优良的技术和完美的服务，赢得众多外资与本地客户的信赖。我们高度关注汽车、生命科学、电子、食品饮料和消费品等垂直行业的发展并基于信息简单、自动化等原则协助该行业领域内的制造商
              </p>
              <div class="tit">高效管理</div>
              <div
                v-for="(item, index) in CompanyProfile"
                :key="index"
                class="list"
              >
                <img src="@/assets/img/juxin19.svg" />
                <div>{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card></div>
    <div class="appear_2"><Card>
      <template v-slot:title> 公司介绍 </template>
      <template v-slot:body>
        <div class="CompanyProfile">
          <img src="@/assets/img/gongsijieshao_phone.png" style="width: 100%;height: 112px;object-fit: cover;"/>
          <div class="head">上海奕盛软件有限公司</div>
          <div class="foot">
            <div class="right">
              <div class="tit">卓越创新</div>
              <p class="twofloor">
                自2000年创建以来,以坚韧的努力和追求卓越的精神不断创新，致力于为中国地区的制造商提供完整的企业管理及IT解决方案帮助打造高效企业迎接全球化发展带来的挑战，并以优良的技术和完美的服务，赢得众多外资与本地客户的信赖。我们高度关注汽车、生命科学、电子、食品饮料和消费品等垂直行业的发展并基于信息简单、自动化等原则协助该行业领域内的制造商
              </p>
              <div class="tit">高效管理</div>
              <div
                v-for="(item, index) in CompanyProfile"
                :key="index"
                class="list"
              >
                <img src="@/assets/img/juxin19.svg" />
                <div>{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card></div>
    <div style="margin: 280px;"></div><!-- 空 -->
    <Card>
      <template v-slot:title>关于我们</template>
      <template v-slot:body>
        <div id="container" class="map"></div>
        <div class="addr">
          <el-row style="margin-bottom: 20px">
            <el-col :span="4" style="text-align: center; font-weight: 600"
              >公司地点</el-col
            >
            <el-col :span="20" style="text-align: left"
              >上海市闵行区浦江镇联航路1588号国家现代服务业软件产业基地SOHO研发楼第8幢</el-col
            >
          </el-row>
          <el-row style="margin-bottom: 20px">
            <el-col :span="4" style="text-align: center; font-weight: 600"
              >联系我们</el-col
            >
            <el-col :span="20" style="text-align: left">021-53015840</el-col>
          </el-row>
          <el-row>
            <el-col :span="4" style="text-align: center; font-weight: 600"
              >&nbsp;</el-col
            >
            <el-col :span="20" style="text-align: left"
              >sales@esun.sh.cn support@esun.sh.cn</el-col
            >
          </el-row>
        </div>
      </template>
    </Card>
    <div style="margin: 280px;"></div><!-- 空 -->
    <Footer></Footer>
  </div>
</template>
    
    <script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import NavBar_mini from "@/components/NavBar_mini.vue";
import Au from "@/assets/img/Au.png";
import logo1 from "@/assets/img/logo1.png";
import ccc from "@/assets/img/ccc.png";
import Card from "@/components/Card.vue";

export default {
  components: {
    NavBar,
    NavBar_mini,
    Footer,
    Card,
  },
  data() {
    return {
      CompanyProfile: [
        "协同、合规与自动化供应链管理",
        "质量＝生命",
        "面向效率与合规的财务管理",
        "自动化业务流程管理",
      ],
    };
  },
  methods:{
    mapInit() {
      // 2. 创建地图示例
      let map = new window.BMapGL.Map("container");
      // 3. 设置中心点坐标
      let point = new window.BMapGL.Point(121.522701, 31.084073);
      let marker = new window.BMapGL.Marker(point); //将点转化成标注点
      map.addOverlay(marker); //将标注点添加到地图上
      // 4. 地图初始化，同时设置地图展示级别
      map.centerAndZoom(point, 20);
      // 5. 开启鼠标滚轮缩放
      map.enableScrollWheelZoom(true);
      let zoomCtrl = new window.BMapGL.ZoomControl(); // 添加缩放控件
      map.addControl(zoomCtrl);
      let cityCtrl = new window.BMapGL.CityListControl(); // 添加城市列表控件
      map.addControl(cityCtrl);
    },
  },
  mounted() {
    this.mapInit();
  },
};
</script>
    
  <style lang="scss" scoped>
.header {
  background-image: url("@/assets/img/you-x-ventures-X8H8vPcelPk-unsplash (2).png");
  background-position-y: -155px;
  height: 327px;
  position: relative;
  width: 100%;
  .title {
    height: 100%;
    background-color: #232585;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 3rem;
    > div {
      font-family: DingTalk JinBuTi;
    }
  }
}
.appear_1{
  display: inline;
}
.appear_2{
  display: none;
}
.map{
  width: 100%;
  height: 15rem;
  margin-top: 30px;
}

@media (max-width: 1320px) {
  .appear_2{
    display: inline;
  }
  .appear_1{
    display: none;
  }
}
</style>
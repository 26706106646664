<template>
  <div class="footer">
    <div class="container">
      <div class="list">
        <div class="tit">{{ $t("message.SiteNavigation") }}</div>
        <div class="content">{{ $t("message.Home") }}</div>
        <div class="content">{{ $t("message.OverallPlan") }}</div>
        <div class="content">{{ $t("message.IndustrySolutions") }}</div>
        <div class="content">{{ $t("message.CustomerStories") }}</div>
        <div class="content">{{ $t("message.TrainingServe") }}</div>
        <div class="content">{{ $t("message.AboutUs") }}</div>
      </div>
      <div class="list">
        <div class="tit">{{ $t("message.ContactUs") }}</div>
        <div class="content">{{ $t("message.TEL") }}</div>
        <div class="content">021-53015840</div>
        <div class="content">{{ $t("message.Email") }}</div>
        <div class="content">
          {{ $t("message.ConsultationAndUnderstanding") }}:sales@esun.sh.cn
        </div>
        <div class="content">
          {{ $t("message.SUPPORT") }}:support@esun.sh.cn
        </div>
      </div>
      <div class="list">
        <div class="tit">{{ $t("message.JoinUs") }}</div>
        <div class="content">{{ $t("message.TalentRecruitment") }}</div>
        <div class="content">
          {{ $t("message.ResumeSubmission") }}:carol.yang@esun.sh.cn
        </div>
      </div>
    </div>
    <div class="feeter">
      <span>
        互联网信息服务备案号:
        <el-link type="primary" @click="jumpToNew">沪ICP备2021022548号-1</el-link>
      </span>
      &nbsp;
      <img src="@/assets/img/gongan.png" style="width: 20px;height: 20px;margin: 0 5px 0 20px;">
      <span>
        公安机关备案号:
        <el-link type="primary" @click="jumpTo">沪公网安备31011202020011号</el-link>
      </span>
      <div style="margin: 10px">
        Copyright © Shanghai Esun Software Ltd. All rights reserved.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods:{
    jumpToNew(){
      window.open('http://beian.miit.gov.cn');
    },
    jumpTo(){
      window.open('https://beian.mps.gov.cn/#/query/webSearch?code=31011202020011');
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #30384a;
  display: flex;
  flex-direction: column;
  padding: 100px 0;
  .container {
    display: flex;
    justify-content: space-evenly;
    .list {
      width: 300px;
      .tit {
        color: #506ff5;
        font-weight: 500;
        padding: 5px 0;
        letter-spacing: 1.83px;

      }
      .content {
        color: white;
        font-weight: normal;
        letter-spacing: 1.83px;
        padding: 5px 0 5px 20px;
      }
    }
  }
  .feeter {
    font-family: Source Han Sans CN;
    font-size:0.875rem;
    font-weight: normal;
    line-height: 26px;
    letter-spacing: 1.83px;
    color: #8691aa;
    text-align: center;
    margin: 20px;
  }
}

@media (max-width: 543px) {
  .footer {
    .container{
      .tit{
        width: 84px;
      }
    }
    .feeter{
      width: 404px;
    }
  }
}
</style>
<template>
  <div>
    <div class="header">
      <div class="appear_1"><NavBar></NavBar></div>
      <div class="appear_2"><NavBar_mini></NavBar_mini></div>
      <div class="title">
        <div>{{ $t("message.IndustrySolutions") }}</div>
      </div>
    </div>
    <div class="container_tabbar">
      <div class="tabbar">
        <div
          class="tabbar_item"
          :class="isActive ? '' : 'active'"
          @click="isActive = 0"
        >
          {{ $t("message.AutomobileIndustry") }}
        </div>
        <div
          class="tabbar_item"
          :class="!isActive ? '' : 'active'"
          @click="isActive = 1"
        >
          {{ $t("message.LifeScienceIndustry") }}
        </div>
      </div>
    </div> 
    <div class="container_AutomobileIndustry" v-if="!isActive">
      <div class="tit">QAD和汽车制造业</div>
      <p>
        全世界大多数市场领先的汽车零部件公司均在使用QAD解决方案。我们的客户包括OEM、零部件供应商和二级市场提供商。QAD企业应用程序可快速实现实施，为企业日益变化的业务需求提供经济高效的技术支持。QAD为汽车行业打造专业版本的企业管理软件，为全球供应商和子供应商提供合适的解决方案；平衡供需，同时减少库存，符合严格的质量标准和交期要求。
      </p>
      <div class="content" v-for="(item,index) in data_CarMode" :key=index>
        <div class="title">
          <img src="@/assets/img/juxin19.svg">
          <span class="label">{{ item.label?item.label:'' }}</span>
        </div>
        <div class="item">
          <p class="item_item" v-for="(item_c,index_c) in item.item" :key=index_c>
            <span class="item_label">{{ item_c.label }}</span>
            {{ item_c.content_one }}
            <br>
            {{ item_c.content_two ? item_c.content_two:''}}
            <br>
            <ul v-if="item_c.children">
              <li v-for="(item_c_child,index_c_child) in item_c.children" :key="index_c_child">{{ item_c_child }}</li>
            </ul>
          </p>
        </div>
      </div>
      <div style="margin-bottom: 50px;">以下流程图展示了 QAD 提供的汽车制造业解决方案的适用范围。</div>
      <img src="@/assets/img/content.png">
    </div>
    <div class="container_AutomobileIndustry" v-if="isActive">
      <div class="tit">QAD与生命科学行业</div>
      <p>
        全世界超过40个国家的400多个生命科学生产基地已部署QAD企业应用程序。从刚成立的新公司到跨国公司，均可享受到QAD系统提供的简单性、可靠性和高绩效。QAD所开发的生命科学套件能够满足全球客户从医疗器械到制药多个市场的需求。QAD致力于为生命科学行业提供支持，确保医疗器械、制药和生物技术制造商能够逐步成为高效企业，同时能够符合严格的法规要求。 QAD的解决方案支持灵活的业务流程配置和多个业务实体，并且能够以多种语言进行部署。部署选项包括根据要求支持全球生命科学企业的需求。QAD的解决方案支持重要的质量要求，包括合规、CAPA、器械历史/电子批量生产记录等要求，以及提供完整的《现行良好制造规范》(cGMP) 检验功能和工具。高级控制程序和审计功能及电子签名支持，有助于符合跟踪符合性规定。
      </p>
      <div class="content" v-for="(item,index) in  data_CarMode" :key=index>
        <div class="title">
          <img src="@/assets/img/juxin19.svg">
          <span class="label">{{ item.label?item.label:'' }}</span>
        </div>
        <div class="item">
          <div class="item_item" v-for="(item_c,index_c) in item.item" :key=index_c>
            <p><span class="item_label">{{ item_c.label }}</span>{{ item_c.content_one ? item_c.content_one:''}}</p>
            <p>{{ item_c.content_two ? item_c.content_two:''}}</p>
            <ul v-if="item_c.children">
              <li v-for="(item_c_child,index_c_child) in item_c.children" :key="index_c_child">{{ item_c_child }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div>以下流程图展示了QAD提供的生命科学版本的适用范围。</div>
      <img src="@/assets/img/content_Life.png">
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import NavBar_mini from "@/components/NavBar_mini.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    NavBar,
    NavBar_mini,
    Footer,
  },
  data() {
    return {
      isActive: parseInt(this.$route.query.isActive),
      data_CarMode:[
        {
          label:'汽车制造业中的关键问题',
          item:[
            {
              label:'符合OEM需求(MMOG/LE):',
              content_one:'QAD提供针对全球OEM质量及厂商合规模式的完整计划。我们为企业提供必要的培训、模板及解决方案，帮助他们获得及保持认证资格，满足客户需求。',
              content_two:'QAD与美国汽车工业行动集团 (AIAG) 和欧洲的Odette合作开发方法与系统来支持全球物料管理操作指南/物流评估 (MMOG/LE) 标准的方法和系统。'
            },
            {
              label:' 识别以低成本进入新市场的方法：',
              content_one:'在中国、印度和朝鲜的机遇需要公司不得不以更低的成本结构在这些市场有效竞争。 QAD目前已在90多个国家提供解决方案。我们了解按地域、OEM或群集划分的当地需求。QAD提供低成本的即时解决方案和预先定义的月度定价模式。'
            },
            {
              label:' 轻松并购和脱离：',
              content_two:'汽车制造业不断兼并、并购、脱离和合营。财务管理对于管理风险和控制成本至关重要。 QAD企业应用程序的架构、全球化设计和简洁性使公司能够快速剥离或并购新工厂。预先定义的流程图可实现迅速实施和旧系统转换。QAD的部件架构可提供高价值解决方案，如高级计划、可视化或运输，无需考虑正在使用的ERP系统。'
            }
          ]
        },
        {
          label:'汽车制造业价值链',
          item:[
            {
              content_one:'QAD为汽车制造业零部件价值链量身定制解决方案，包括 OEM、多级供应商和售后服务提供商。',
            }
          ]
        },
        {
          label:'汽车制造业市场细分',
          item:[
            {
              label:'汽车制造业供应商：',
              content_one:'QAD为全球汽车制造业供应商群体提供解决方案，包括系统供应商及所有子级供应商。QAD采用关键行业领先客户提供的信息与指引来开发其解决方案。',
            },
            {
              label:'汽车制造业二级市场：',
              content_one:'QAD 提供管理汽车制造业二级市场的解决方案，包括销售预测、计划、仓储管理及物流等高级功能。'
            },
            {
              label:'装配和重型设备：',
              content_two:'QAD 为装配和重型设备制造商提供按照订单生产或备货生产的完整解决方案'
            }
          ]
        },
        {
          label:'汽车制造业解决方案的功能优势',
          item:[
            {
              content_one:'QAD为汽车行业打造专业版本，为全球供应商提供平衡供需的合适解决方案，同时可减少库存及满足交货计划及严格的质量及交付要求。',
              content_two:'QAD提供的解决方案能够满足大多数汽车制造业的业务流程要求，包括：',
              children:['发布管理','物料管理工作 指南/物流评估 (MMOG/LE)','供应商进度安排','精益生产','交付绩效','EDI','供应商绩效','质量管理','可追溯性','工程变更']
            },
          ]
        },
      ],
      data_LifeSciences:[
        {
          label:'QAD令您工作更有效、更智能',
          item:[
            {
              children:['完全的 IQ/OQ/PQ 检验支持','FDA 法规遵循','集成设备及批量生产历史报告 (DHR)','合格的 SaaS 环境','整个生命周期的批量可追溯性','支持行业最佳实践 及流程','直观和简便的用户界面','符合当地的法律法规']
            }
          ]
        },
        {
          label:'基于网络的生命科学行业 ERP 软件',
          item:[
            {
              content_one:'QAD致力于帮助生命科学行业制造商。QAD让企业运作更有效的另一方式是通过我们基于网络的ERP软件QAD On Demand。这项基于网络的ERP解决方案能够让生命科学企业关注其业务，而非其软件。我们的ERP软件的在线功能极大地降低了实施成本，减少了IT维护。',
              content_two:'QAD On Demand生命科学套件提供与On Demand标准版的功能相同，但还提供符合美国食品和药物管理局 (FDA) 和《现行良好制造规范》(cGMP) 检验要求的合格环境和必要的标准操作步骤 (SOP)。QAD认识到我们的客户对于我们的信任，正如对他们的企业资源计划解决方案提供商一样。这就是为什么如此多的生命科学企业选择QAD的原因。',
            }
          ]
        },
        {
          label:'生命科学行业中的关键问题',
          item:[
            {
              label:'符合全球法规：',
              content_one:'全球政府对生命科学制造商强制实施质量规范，无论其销售或是分销产品。一般而言，法规涉及生产、销售、市场营销和分销产品各个方面。未能合规可导致高额罚款和产品召回。符合《现行良好制造规范》(cGMP) 是展示和验证系统所面临的主要压力。',
              content_two:"QAD 提供有助于确保用户符合所有流程和保障措施的工具。"
            },
            {
              label:'推出新产品：',
              content_one:'达到专利、科技、创新极限对于促进生命科学企业的成功至关重要。',
              content_two:"QAD企业应用程序提供所需工具，用于从发现和开发到市场营销和生产的各个阶段管理资源和控制成本。"
            },
            {
              label:'降低风险：',
              content_one:'生命科学行业要求拥有安全和可审计的系统如果产品面临调查，需要访问从发现到生产的详细产品历史。',
              content_two:"QAD企业应用程序为制造商提供了工具，可管理系统验证、电子记录和签名、综合的审记跟踪和批次跟踪，从而降低风险和实现经济高效的管理合规。"
            },
            {
              label:'提高供应链的绩效：',
              content_one:'供应链经理需要对广泛的供应链完全可见，以便从供应商集合信息并与客户进行合作。',
              content_two:"QAD企业应用程序提供诸如供应链门户等工具，可简化规定的安全访问。"
            }
          ]
        },
        {
          label:'生命科学行业价值链',
          item:[
            {
              content_one:'生命科学价值链参与者数量也许并不庞大，但其关系尤其紧密、持久且深入。且由于法规对变更的约束，供应链倾向于保持稳定。',
            },
          ]
        },
        {
          label:'生命科学行业市场细分',
          item:[
            {
              label:'医疗器械：',
              content_one:'QAD 支持医疗器械制造对于一级到三级（上市前）的需求。QAD 的批次和序列号跟踪、现场管理控制和全球贸易合规解决方案有助于确保公司按照《现行良好制造规范》(cGMP) 运作，符合全球贸易要求。',
            },
            {
              label:'制药：',
              content_one:'QAD 支持处方药物制造商，包括一般药物制造商。QAD 通过计划功能、物料控制、现场管理可见性和分销活动为医药制造商提供帮助。',
            },
            {
              label:'生物技术：',
              content_one:'QAD 使生物技术公司能够以非常短的期限内管理迅速增长。快速实施功能包括管理验证，QAD 深受正处于迅猛发展公司的欢迎。',
            }
          ]
        },
        {
          label:'生命科学行业解决方案功能优势',
          item:[
            {
              content_one:'QAD生命科学行业解决方案支持重要的质量要求，包括纠正措施/预防措施 (CAPA) 和负面影响报告，并提供全面的检验功能。QAD 提供一套工具，有助于符合《现行良好制造规范》(cGMP) 的检验，包括高级控制程序、审计跟踪和使用电子签名。',
              content_two:'QAD Enterprise Applications能够满足生命科学行业公司在大多数领域中的业务流程需求，包括：',
              children:['cGMP - 计算机系统验证','美国联邦法规 21 第 11 部分 (21CFR11)','生产和合同 生产','质量管理','序列化和电子谱系','前后批次/序列 跟踪','在制品批次跟踪','EDI','运输管理','客户服务和支持','工程变列控制','合同、促销和返利','需求计划','包装','供应链门户','法规检验合规',' 库存管理','分销']
            },
          ]
        }
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$marginbotton: 100px;
.header {
  background-image: url("@/assets/img/you-x-ventures-X8H8vPcelPk-unsplash (3).png");
  height: 20rem;
  position: relative;
  width: 100%;
  background-position: 0 -300px;
  .title {
    height: 100%;
    background-color: #232585;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 3rem;
    > div {
      font-family: DingTalk JinBuTi;
    }
  }
}
.container_tabbar {
  background-color: #f7f8fa;
  margin-bottom: $marginbotton;
  .tabbar {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    .tabbar_item {
      padding: 20px;
      cursor: pointer;
      &.active {
        border-bottom: 2px solid #2f54eb;
        color: #2f54eb;
      }
    }
  }
}
.container_AutomobileIndustry {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 200px;
  .tit {
    font-family: DingTalk JinBuTi;
    font-size: 2.25rem;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: 1px;
    color: #2f54eb;
    margin-bottom: $marginbotton/3;
  }
  >p{
    margin-bottom: $marginbotton;
  }
  >img{
    width: 75rem;
    display: block;
  }
  .content{
      .title{
      .label{
        font-family: Source Han Sans CN;
        font-size: 1.5rem;
        font-weight: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #2F54EB;
      }
    }
    .item{
      .item_item{
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 24px;
        letter-spacing: 0.83px;
        .item_label{
          font-family: Source Han Sans CN;
          font-weight: 900;
          color: #30384A;
        }
      }
    }
  }
}
.appear_1{
  display: inline;
}
.appear_2{
  display: none;
}

@media (max-width: 1320px) {
  .container_AutomobileIndustry{
    width: 780px;
    >img{
      width: 49rem;
    }
  }
  .appear_2{
    display: inline;
  }
  .appear_1{
    display: none;
  }
}

@media (max-width: 840px) {
  .container_AutomobileIndustry{
    width: 93vw;
    >img{
      width: 93vw;
    }
  }
  .appear_2{
    display: inline;
  }
  .appear_1{
    display: none;
  }
}
</style>
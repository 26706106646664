import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue' 
import Index_Phone from '../views/Index_phone.vue'
import OverallPlan from '../views/OverallPlan.vue'
import OverallPlan_phone from '../views/OverallPlan_phone.vue'
import IndustrySolutions from '../views/IndustrySolutions.vue'
import IndustrySolutions_phone from '../views/IndustrySolutions_phone.vue'
import CustomerStories from '../views/CustomerStories'
import CustomerStories_phone from '../views/CustomerStories_phone'
import AboutUs from '../views/AboutUs'
import AboutUs_phone from '../views/AboutUs_phone'
import TrainingServe from '../views/TrainingServe'
import TrainingServe_phone from '../views/TrainingServe_phone'
import i18n from '@/lang'
console.log(i18n.t('message.Home'))
Vue.use(VueRouter)
let _isMobile=()=>{
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}
let routes = []
if (_isMobile()) {
  console.log('移动端')
   routes = [
    {
      path: '/',
      component: Index_Phone,
      // name:i18n.t('message.Home')
      name:'首页'
    },
    {
      path: '/OverallPlan_phone',
      component: OverallPlan_phone,
      name:'总体方案'
    },
    {
      path: '/IndustrySolutions_phone',
      component: IndustrySolutions_phone,
      name:'行业方案'
    },
    {
      path: '/CustomerStories_phone',
      component: CustomerStories_phone,
      name:'客户案例'
    },
    {
      path: '/TrainingServe_phone',
      component: TrainingServe_phone,
      name:'培训服务'
    },
    {
      path: '/AboutUs_phone',
      component: AboutUs_phone,
      name:'关于我们'
    },
  ]
} else {
  console.log('PC端')
   routes = [
    {
      path: '/',
      component: Index
    },
    {
      path: '/OverallPlan',
      component: OverallPlan
    },
    {
      path: '/IndustrySolutions',
      component: IndustrySolutions
    },
    {
      path: '/CustomerStories',
      component: CustomerStories
    },
    {
      path: '/AboutUs',
      component: AboutUs
    },
    {
      path: '/TrainingServe',
      component: TrainingServe
    }
  ]
}


const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})


router.afterEach((to, from) => {  
  // 跳转到页面顶部  
  window.scrollTo(0, 0);  
  // 或者使用更平滑的滚动方式  
  // window.scrollTo({ top: 0, behavior: 'smooth' });  
});


// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
<template>
  <div class="foot">
    <div class="top">
      <div class="list left">
        <div class="tit">{{ $t("message.SiteNavigation") }}</div>
        <div class="content">{{ $t("message.Home") }}</div>
        <div class="content">{{ $t("message.OverallPlan") }}</div>
        <div class="content">{{ $t("message.IndustrySolutions") }}</div>
        <div class="content">{{ $t("message.CustomerStories") }}</div>
        <div class="content">{{ $t("message.TrainingServe") }}</div>
        <div class="content">{{ $t("message.AboutUs") }}</div>
      </div>
      <div class="list center">
        <div class="tit">{{ $t("message.ContactUs") }}</div>
        <div class="content">{{ $t("message.TEL") }}</div>
        <div class="content">021-53015840</div>
        <div class="content">{{ $t("message.Email") }}</div>
        <div class="content">
          {{ $t("message.ConsultationAndUnderstanding") }}:
        </div>
        <div class="content">
            sales@esun.sh.cn
        </div>
        <div class="content">
          {{ $t("message.SUPPORT") }}:
        </div>
        <div class="content">
          support@esun.sh.cn
        </div>
      </div>
      <div class="list right">
        <div class="tit">{{ $t("message.JoinUs") }}</div>
        <div class="content">{{ $t("message.TalentRecruitment") }}</div>
        <div class="content">
          {{ $t("message.ResumeSubmission") }}:
        </div>
        <div class="content">
          carol.yang@esun.sh.cn
        </div>
      </div>
    </div>
    <div class="bottom">
        <div class="txt">互联网信息服务备案号:</div>
        <div class="txt">
          <el-link type="primary" @click="jumpToNew">沪ICP备2021022548号-2</el-link>
        </div>
        <div class="txt">
          <img src="@/assets/img/gongan.png" style="width: 1rem;height: 1rem;margin-right: 5px;">公安机关备案号:</div>
        <div class="txt">
          <el-link type="primary" @click="jumpTo">沪公网安备31011202020011号</el-link>
        </div>
        <div class="end">Copyright © Shanghai Esun Software Ltd. All rights reserved.</div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    jumpToNew(){
      window.open('http://beian.miit.gov.cn');
    },
    jumpTo(){
      window.open('https://beian.mps.gov.cn/#/query/webSearch?code=31011202020011');
    }
  }
};
</script>
<style lang="scss" scoped>
.foot{
    height: 60vh;
    width: 100%;
    background-color: #30384A;
    position: relative;
    bottom: 0;
    padding:0 0.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 2rem;
    .top{
        display: flex;
        width: 100%;
        font-size: 0.75rem;
        justify-content: space-around;
        // .left{
        //     width: 30%;
        // }
        // .center{
        //     width: 40%;
        // }
        // .right{
        //     width: 30%;
        // }
        >div{
            .tit{
                color: #506FF5;
                margin: 0.5rem 0;
            }
            .content{
                color: white;
                margin: 0.5rem 0;
                word-wrap: break-word;
            }
        }
    }
    .bottom{
        display: flex;
        flex-direction: column;
        font-size: 0.6rem;
        color: #8691aa;
        .txt{
          margin: 0px 30px;
        }
        .end{
          text-align:center;
          margin: 10px;
        }
    }
}
</style>
<template>
    <div class="container_IndustrySolutions_phone">
      <div class="header">
        <div class="mash">
          <NavBar_phone></NavBar_phone>
          <div class="title">{{ $t("message.CustomerStories") }}</div>
        </div>
      </div>
      <div class="coustom" v-for="(item,index) in CustomerStories" :key=index>
        <div class="top">
            <img :src="item.img_src">
        </div>
        <div class="bottom">
            <div class="label">{{item.label  }}</div>
            <div class="EN_label">{{item.EN_label  }}</div>
            <div class="txt">{{item.txt  }}</div>
        </div>
      </div>
      <Footer_phone></Footer_phone>
    </div>
  </template>
    <script>
  import NavBar_phone from "@/components/NavBar_phone.vue";
  import Footer_phone from "@/components/Footer_phone.vue";
  import Au from "@/assets/img/Vutoliv_phone.png";
  import logo1 from "@/assets/img/ARN_phone.png";
  import ccc from "@/assets/img/Med.png";
  export default {
    components: {
      NavBar_phone,
      Footer_phone,
    },
    data() {
      return {
        CustomerStories: [
          {
            label: "奥托立夫",
            EN_label: "AUTOLIV",
            txt: "通过ERP系统定制开发，实现了管理的标准化，资源的共享和流程的标准化。助力奥托立夫全球化扩张。",
            img_src: Au,
          },
          {
            label: "安庆环新集团",
            EN_label: "ARN",
            txt: "通过车间管理平台系统的定制开发，实现了生产过程条码跟踪，数据采集、监控与防错，减少了管理成本。",
            img_src: logo1,
          },
          {
            label: "苏州美创",
            EN_label: "MEICHUANG",
            txt: "通过QAD系统实施，实现全流程追溯，满足监管要求，通过了TUV13485质量管理体系认证和GSP审核",
            img_src: ccc,
          },
        ],
      };
    },
  };
  </script>
    <style lang="scss" scoped>
  .container_IndustrySolutions_phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .header {
      height: 10rem;
      width: 100%;
      background-image: url("@/assets/img/you-x-ventures-X8H8vPcelPk-unsplash.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      .mash {
        width: 100%;
        background-color: rgba(35, 37, 133, 0.8);
        // opacity: 0.8;
        height: 100%;
        position: relative;
        .title {
          color: white;
          font-family: DingTalk JinBuTi;
          font-size: 2rem;
          text-align: center;
          padding-top: 6rem;
        }
      }
    }
    .coustom{
        width: 80%;
        border: 1px solid #D3D5D6;
        align-items: center;
        margin-top: 2rem;
        .top{
            width: 100%;
            height: 8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #D3D5D6;
            >img{
              width: 30%;
            }
        }
        .bottom{
            padding: 1.5rem;
            box-sizing: border-box;
            .label{
                font-family: DingTalk JinBuTi;
                font-weight: normal;
                
            }
            .EN_label{
                font-family: 思源黑体;
                font-weight: 500;
                color: #C8CCDB;
                margin: 0.5rem 0;
            }
            .txt{
                font-family: 思源黑体;
                font-size: 0.75rem;
                color: #30384A;
            }
        }
    }
  }
  </style>
<template>
    <div class="main">
      <div class="header">
        <NavBar_phone></NavBar_phone>
        <div class="title">自动化+高效+智能<br>为制造赋能</div>
        <div class="txt">Automation<br>+<br>Efficiency<br>+<br>Intelligence</div>
        <div class="txt">Empowering<br>Manufacturing</div>
        <img class="banner" src="@/assets/img/banner.png" />
      </div>
      <Card_phone>
        <template  v-slot:title>公司介绍</template>
        <template  v-slot:banner><img src="@/assets/img/gongsijieshao_phone.png" /></template>
        <template v-slot:body>
          <div class="CompanyProfile">
            <div class="head">上海奕盛软件有限公司</div>
            <div class="label">卓越创新</div>
            <div class="txt">自2000年创建以来,以坚韧的努力和追求卓越的精神不断创新，致力于为中国地区的制造商提供完整的企业管理及IT解决方案帮助打造高效企业迎接全球化发展带来的挑战，并以优良的技术和完美的服务，赢得众多外资与本地客户的信赖。我们高度关注汽车、生命科学、电子、食品饮料和消费品等垂直行业的发展并基于信息简单、自动化等原则协助该行业领域内的制造商</div>
            <div class="label">高效管理</div>
            <div class="txt">作为全球化之下的制造商追求的目标，是先进制造商标志之一，我们基于企业数据平台帮助制造商达成以下高效运营目标：</div>
            <ul>
              <li 
                v-for="(item, index) in CompanyProfile"
                :key="index"
                class="list">
                <img src="@/assets/img/juxin19.svg" />
                <div>{{ item }}</div>
              </li>
          </ul>
          </div>
        </template>
    </Card_phone>
    <Card_phone>
        <template  v-slot:title>总体方案</template>
        <template v-slot:body>
          <div class="CompanyProfile">
            <div class="txt">公司基于ERP核心，衍生后ERP时代打造企业数字化，智能化管理，构建一体化解决方案体系，整合软硬件协同的专业咨询服务，协助制造业建立企业级大数据，借助云计算、机器学习、数据湖、机器流程自动化RPA 、IoT物联网等先进信息技术手段，走向智能工厂
            </div>
            <img src="@/assets/img/image.png" />
          </div>
        </template>
    </Card_phone>
    <Card_phone>
        <template  v-slot:title>行业方案</template>
        <template v-slot:body>
          <div class="IndustrySolutions" v-for="(item,index) in IndustrySolutions" :key="index">
            <img :src="item.img_src" />
            <div class="label">{{ item.label }}</div>
            <div class="txt" style="margin-bottom: 20px;">{{ item.txt_1 }}</div>
            <div style="width: 80%;">
              <div class="button"
              @click="$router.push({ path: '/IndustrySolutions_phone', query: { isActive: index }})">
                <i class="el-icon-arrow-right"></i>查看更多
            </div>
            </div>
          </div>
        </template>
    </Card_phone>
    <Card_phone>
        <template  v-slot:title>客户案例</template>
        <template v-slot:body>
          <div class="IndustrySolutions" v-for="(item,index) in CustomerStories" :key="index">
            <div class="banner"><img :src="item.img_src" /></div>
            <div class="label">{{ item.label }}</div>
            <div class="label_en">{{ item.EN_label }}</div>
            <div class="txt">{{ item.txt }}</div>
          </div>
        </template>
    </Card_phone>
    <Card_phone>
        <template  v-slot:title>培训服务</template>
        <template v-slot:body>
          <div class="TrainingServe">
            <div class="head">
              <img src="@/assets/img/vector.svg"/>
              <el-link style="color: #2f54eb; font-size: 80%;" @click="$router.push({ path: '/TrainingServe_phone', query: { isActive: 0 }})" ><i class="el-icon-right"></i>查看更多</el-link>
            </div>
            <div class="label">QAD模块培训</div>
            <div class="txt">QAD标准模块培训</div>
          </div>
          <div class="TrainingServe">
            <div class="head">
              <img src="@/assets/img/vector.svg" />
              <el-link style="color: #2f54eb; font-size: 80%;" @click="$router.push({ path: '/TrainingServe_phone', query: { isActive: 1 }})" ><i class="el-icon-right"></i>查看更多</el-link>
            </div>
            <div class="label">QAD模块培训</div>
            <div class="txt">QAD标准模块培训</div>
          </div>
        </template>
    </Card_phone>
    <Card_phone>
        <template  v-slot:title>关于我们</template>
        <template v-slot:body>
          <div class="AboutUs">
            <div id="container" style="width: 100%; height: 8rem;"></div>
            <div class="bottom">
              <div class="label">公司地点</div>
              <div class="txt">上海市闵行区浦江镇联航路1588号国家现代服务业软件产业基地SOHO研发楼第8幢</div>
              <div class="label">联系我们</div>
              <div class="txt">021-53015840</div>
              <div class="txt">sales@esun.sh.cn</div>
              <div class="txt">support@esun.sh.cn</div>
            </div>
          </div>
        </template>
    </Card_phone>
    <Footer_phone></Footer_phone>
    </div>
  </template>
  
  <script>
  import NavBar_phone from "@/components/NavBar_phone.vue";
  import Footer_phone from "@/components/Footer_phone.vue";
  import Card_phone from "@/components/Card_phone.vue";
  import car from "@/assets/img/car_phone.png";
  import life from "@/assets/img/science_phone.png";
  import Au from "@/assets/img/Vutoliv_phone.png";
  import logo1 from "@/assets/img/ARN_phone.png";
  import ccc from "@/assets/img/Med.png";
  export default {
    name: "HomeView",
    components: {
      NavBar_phone,
      Card_phone,
      Footer_phone,
    },
    data() {
      return {
        CompanyProfile: [
          "协同、合规与自动化供应链管理",
          "质量＝生命",
          "面向效率与合规的财务管理",
          "自动化业务流程管理",
        ],
        IndustrySolutions: [
          {
            label: "汽车行业",
            txt_1:
              "随着宏观环境的变化，中国汽车产业正步入转型升级的重质量发展时代。在各项战略举措的支持下深化改革，市场环境被显著重塑。",
            txt_2:
              "汽车行业高管将数字化转型作为优先举措，40%的受访企业已从数字化转型中实现了显著的投资回报率，逾半数的受访者计划在来年大幅增加数字化转型方面的投资。在驱动因素方面，尽管生产和运营目标是推动数字化转型最重要的两大因素，而汽车行业则更多地受竞争压力和创新需求的推动。在推动数字化创新的职能部门中，信息技术（IT）部门高居首位，不过供应链、产品设计、市场和规划方面的数字化转型相对成熟，这也将继续成为未来投资的优先领域。",
            img_src: car,
          },
          {
            label: "生命科学",
            txt_1:
              "在过去几年中，中国国内医药消费增长迅猛，医药行业销售收入平均年增长达21.4%。受强劲的经济增长、不断加剧的城市化进程以及老龄化带来的就医需求等因素的驱动，中国将成为世界最大的医药市场之一。到2020年，中国的医药市场销售额预计达到2200亿美元，中国将成为仅次于美国的世界第二大医药市场。",
            img_src: life,
          },
        ],
        CustomerStories: [
          {
            label: "奥托立夫",
            EN_label: "AUTOLIV",
            txt: "通过ERP系统定制开发，实现了管理的标准化，资源的共享和流程的标准化。助力奥托立夫全球化扩张。",
            img_src: Au,
          },
          {
            label: "安庆环新集团",
            EN_label: "ARN",
            txt: "通过车间管理平台系统的定制开发，实现了生产过程条码跟踪，数据采集、监控与防错，减少了管理成本。",
            img_src: logo1,
          },
          {
            label: "苏州美创",
            EN_label: "MEICHUANG",
            txt: "通过QAD系统实施，实现全流程追溯，满足监管要求，通过了TUV13485质量管理体系认证和GSP审核",
            img_src: ccc,
          },
        ],
      };
    },
    methods: {
      mapInit() {
        // 2. 创建地图示例
        let map = new window.BMapGL.Map("container");
        // 3. 设置中心点坐标
        let point = new window.BMapGL.Point(121.522701, 31.084073);
        let marker = new window.BMapGL.Marker(point); //将点转化成标注点
        map.addOverlay(marker); //将标注点添加到地图上
        // 4. 地图初始化，同时设置地图展示级别
        map.centerAndZoom(point, 12);
        // 5. 开启鼠标滚轮缩放
        map.enableScrollWheelZoom(true);
        let zoomCtrl = new window.BMapGL.ZoomControl(); // 添加缩放控件
        map.addControl(zoomCtrl);
        let cityCtrl = new window.BMapGL.CityListControl(); // 添加城市列表控件
        map.addControl(cityCtrl);
      },
    },
    mounted() {
      this.mapInit();
    },
  };
  </script>
  <style lang="scss" scoped>
  .main{
    display: flex;
    flex-direction: column;
    align-items: center;
    ul{
      list-style: none;
      margin: 0;
      padding: 0;
    }
    img{
      display: block;
    }
    .header{
      background-color: #232585;
      height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      overflow: hidden;
      padding-bottom: 5vh;
      .title{
        margin: 100px 0 20px 0;
        width: 80vw;
        font-family: DingTalk JinBuTi;
        font-size: 2.5rem;
        font-weight: normal;
        color: white;
      }
      .txt{
        color: white;
        font-size: 0.95rem;
        margin-bottom: 10px;
        font-family: DingTalk JinBuTi;
        width: 80vw;
      }
      .banner{
        width: 140%;
        display: block;
        position: absolute;
        right: -39vw;
        bottom: 3vh;
      }
    }
  }

  @media (orientation: landscape) {
    .main{
      display: flex;
      flex-direction: column;
      align-items: center;
      ul{
        list-style: none;
        margin: 0;
        padding: 0;
      }
      img{
        display: block;
      }
      .header{
        background-color: #232585;
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        overflow: hidden;
        padding-bottom: 5vh;
        .title{
          margin: 60px 0 20px 0;
          width: 80vw;
          font-family: DingTalk JinBuTi;
          font-size: 3rem;
          font-weight: normal;
          color: white;
        }
        .txt{
          color: white;
          font-size: 1.2rem;
          margin-bottom: 10px;
          font-family: DingTalk JinBuTi;
          width: 80vw;
        }
        .banner{
          width: 68%;
          display: block;
          position: absolute;
          right: -6vw;
          bottom: -5vh;
        }
      }
    }
  }
  </style>
  
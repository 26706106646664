<template>
  <div class="container_OverallPlan_phone">
    <div class="header">
      <div class="mash">
        <NavBar_phone></NavBar_phone>
        <div class="title">{{ $t("message.OverallPlan") }}</div>
      </div>
    </div>
    <Card_phone>
      <template v-slot:body>
        <div class="CompanyProfile">
          <div class="txt">
            公司基于ERP核心，衍生后ERP时代打造企业数字化，智能化管理，构建一体化解决方案体系，整合软硬件协同的专业咨询服务，协助制造业建立企业级大数据，借助云计算、机器学习、数据湖、机器流程自动化RPA
            、IoT物联网等先进信息技术手段，走向智能工厂
          </div>
          <img src="@/assets/img/image.png" />
          <ul>
            <li
              v-for="(item, index) in CompanyProfile"
              :key="index"
              class="list"
            >
              <img src="@/assets/img/juxin19.svg" />
              <div>{{ item }}</div>
            </li>
          </ul>
        </div>
      </template>
    </Card_phone>
    <div class="system">
      <div class="title">系统组件</div>
      <div class="item" v-for="(item, index) in SystemComponents"
          :key="index">
        <div class="label" 
          >
          <img src="@/assets/img/juxin19.svg" />
          <span>{{ item.label }}</span>
        </div>
        <p>{{ item.txt }}</p>
      </div>
    </div>
    <Footer_phone></Footer_phone>
  </div>
</template>
<script>
import NavBar_phone from "@/components/NavBar_phone.vue";
import Card_phone from "@/components/Card_phone.vue";
import Footer_phone from "@/components/Footer_phone.vue";
export default {
  components: {
    NavBar_phone,
    Card_phone,
    Footer_phone
  },
  data() {
    return {
      CompanyProfile: [
        "协同、合规与自动化供应链管理",
        "质量＝生命",
        "面向效率与合规的财务管理",
        "自动化业务流程管理",
      ],
      SystemComponents: [
        {
          label: "WMS仓库管理组件",
          txt: "是一套单独运作的仓库管理系统，结合条码系统共同进行仓库管理。系统包括采购，销售，生产以及仓库事务管理四大功能模块，系统管理模块，同时WMS可与ERP系统保持着数据的紧密联系，实现两个系统相互连接并且相对独立的运作。",
        },
        {
          label: "MRS组件",
          txt: "电子看板系统（Material Replenishment System，简称“MRS”），用于制造企业，仓库超市料架往车间产线补料的电子系统。提供二种方式进行补料，【第一种】通过生产线消耗产生需求，备料员根据系统需求明细去仓库超市取料；【第二种】通过顺序生产计划提前预知要生产的成品及数量，通过BOM分解，产生需求，备料员根据系统需求明细去仓库超市取料；",
        },
        {
          label: "DWH 数字化仓库组件",
          txt: "原材料仓库数字化改善针对来料上架、高位补料至低位、超市货格分配规划和超市货格补料至产线提出优化需求;利用大数据分析计算，遗传算法等先进技术提高仓库面积利用率，员工执行效率；",
        },
        {
          label: "SOP组件",
          txt: "供应商交互平台包括供应商计划发布到收货，以及供应商开票两部分功能;提供供应商协同管理效率。集成ERP 采购，收货，财务三单匹配功能；",
        },
        {
          label: "MES组件",
          txt: "MES系统是一套面向制造企业车间执行层的生产信息化管理系统；主要包括生产计划与调度、物料平衡及物流、库存、工艺技术、过程监控、质量、HSE、设备、能源、成本、绩效管理等；设备数据采集，数据分析，应用过程；",
        },
        {
          label: "DCI组件",
          txt: "“DCI” 是一种软件对软件的集成工具，在无需译码的情况下，通过企业软件和其他企业的应用软件之间高效的互动性来帮助企业优化其业务流程，同时又能控制其运营成本，自动完成一些关键性的任务，例如数据同步；减少用户界面操作和降低手动数据输入的时间成本；",
        },
        {
          label: "目视看板组件",
          txt: "电子看板是目视化管理的一种表现形式，即对数据、信息等的状况一目了然地表现，主要是对于管理项目、特别是数据进行的透明化管理活动；",
        },
        {
          label: "BPM 业务流程管理组件",
          txt: "BPM系统于业务流程进行建模、开发、集成、应用、管理监控和分析优化的软件。解决管理和业务的“无序性”和“低效率”，降低成本，从而帮助企业提高工作质量、提升利润，提高服务质量和效率，提高服务形象。并能够以最低的信息化投入成本和响应时间，应对来自于外界和内部的需求变化；",
        },
        {
          label: "SRM供应商关系管理组件",
          txt: "作为新一代采购管理与协作平台，利用先进信息技术与采购流程完美结合，帮助企业快速搭建数字化采购体系，实现采购管理、供应商管理，采购寻源等采购业务数字化。精准把控采购的每一个环节，大幅提升采购管理能力，保障企业采购业务流畅运转；",
        },
        {
          label: "SRM供应商关系管理组件",
          txt: "作为新一代采购管理与协作平台，利用先进信息技术与采购流程完美结合，帮助企业快速搭建数字化采购体系，实现采购管理、供应商管理，采购寻源等采购业务数字化。精准把控采购的每一个环节，大幅提升采购管理能力，保障企业采购业务流畅运转；",
        },
        {
          label: "CRM 客户关系管理组件",
          txt: "客户管理解决方案运行和衡量营销活动，管理整个销售生命周期中的销售机会，优化订单和履行流程，同时通过快速预测客户需求来确保客户保留率;",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.container_OverallPlan_phone {
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    height: 10rem;
    width: 100%;
    background-image: url("@/assets/img/zongtifanganbanner.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .mash {
      width: 100%;
      background-color: rgba(35, 37, 133, 0.8);
      // opacity: 0.8;
      height: 100%;
      position: relative;
      .title {
        color: white;
        font-family: DingTalk JinBuTi;
        font-size: 2rem;
        text-align: center;
        padding-top: 6rem;
      }
    }
  }
  .system{
    width: 80%;
    .title{
      color:#2F54EB;
      font-family: DingTalk JinBuTi;
      font-size: 1.5rem;
      font-weight: normal;
      margin-bottom: 2rem;
    }
    .item{
      display: flex;
      flex-direction: column;
      .label{
        color: #2F54EB;
        font-size: 1rem;
        >img{
        width: 0.75rem;
        margin-right: 0.5rem;
        }
      }
      
      >p{
        color: #666B70;
        font-size: 0.75rem;
      }
    }
  }
}
</style>
<template>
    <div class="container">
      <img src="@/assets/img/esun_logo.svg" style="margin-left: 8px;"/>
      <img src="@/assets/img/chouti.svg" @click="toggleDrawer()" style="margin-right: 28px;"/>
      <div class="menu_help">
        <div class="nav">
          <img src="@/assets/img/logo.svg" />
          <i class="el-icon-close" @click="toggleDrawer()" style="transform: scale(1.5);"></i>
        </div>
        <div class="list">
          <div
            class="item"
            v-for="(item, index) in routes"
            :key="index"
            @click="JumpTo(item.path)"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="lang"><span @click="change">中/EN</span></div>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        routes: [
          { label: this.$t("message.Home"), path: "/" },
          { label: this.$t("message.OverallPlan"), path: "/OverallPlan" },
          {
            label: this.$t("message.IndustrySolutions"),
            path: "/IndustrySolutions",
          },
          {
            label: this.$t("message.CustomerStories"),
            path: "/CustomerStories",
          },
          {
            label: this.$t("message.TrainingServe"),
            path: "/TrainingServe",
          },
          { label: this.$t("message.AboutUs"), path: "/AboutUs" },
        ],
      };
    },
    // computed:{
    //     routes(){
    //         return this.$router.options.routes
    //     }
    // },
    mounted() {
      console.log(this.$router);
    },
    methods: {
      JumpTo(path) {
        this.$router.push(path);
      },
      change() {
        this.toggleDrawer();
        if (this.$i18n.locale == "en") {
          this.$i18n.locale = "zh";
          localStorage.setItem("lang", this.$i18n.locale);
        } else {
          this.$i18n.locale = "en";
          localStorage.setItem("lang", this.$i18n.locale);
        }
      },
      toggleDrawer() {
        var drawer = document.querySelector('.menu_help');  
        if (drawer.classList.contains('menu_help_open')) {  
          // 如果抽屉已经打开，则关闭它  
          drawer.classList.remove('menu_help_open');  
          // 可选：添加关闭后的动画结束处理，比如隐藏按钮或更改文本  
        } else {  
          // 如果抽屉是关闭的，则打开它  
          drawer.classList.add('menu_help_open');  
          // 可选：添加打开后的动画开始处理，比如更改按钮文本  
        }  
      }
    },
  };
  </script>
  <style lang="scss" scoped>
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    position: fixed;
    background-color: rgb(35, 37, 133, 1);
    width: 100%;
    top: 0;
    z-index: 99;
    .menu_help {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      background-color: rgba(255, 255, 255, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 99;
      right: -100%; /* 初始位置在屏幕右侧之外 */  
      transition: transform 0.3s ease-in-out; /* 平滑过渡效果 */  
      .nav {
        width: 80%;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
      }
      .list {
        width: 70%;
        margin-top: 4rem;
        .item {
          padding: 2rem 0 1rem 1rem;
          border-bottom-style: solid;
          border-width: 1px;
          border-color: rgb(180, 180, 180);
          font-family: 思源黑体;
          font-weight: 550;
          font-size: 19px;
          color: #252c33;
        }
        .item:hover {
          color: #2f54eb;
        }
      }
      .lang {
        width: 70%;
        margin-top: 10vh;
        font-family: 思源黑体;
        font-weight: 600;
        font-size: 18px;
        color: #252c33;
      }
      .lang:hover {
        color: #2f54eb;
      }
    }
    .menu_help_open{
      transform: translateX(-100%); /* 向左滑动显示抽屉 */  
    }
  }
  </style>
<template>
  <div class="container_IndustrySolutions_phone">
    <div class="header">
      <div class="mash">
        <NavBar_phone></NavBar_phone>
        <div class="title">{{ $t("message.TrainingServe") }}</div>
      </div>
    </div>
    <div class="container_tabbar">
      <div class="tabbar">
        <div
          class="tabbar_item"
          :class="isActive ? '' : 'active'"
          @click="isActive = 0"
        >
          <!-- {{ $t("message.AutomobileIndustry") }} -->
          QAD模块培训
        </div>
        <div
          class="tabbar_item"
          :class="!isActive ? '' : 'active'"
          @click="isActive = 1"
        >
          <!-- {{ $t("message.LifeScienceIndustry") }} -->
          定制开发培训
        </div>
      </div>
    </div>
    <div class="QAD" v-if="!isActive">
        <div class="title">QAD模块培训</div>
        <div class="txt">正在建设中</div>
    </div>
    <div class="QAD" v-if="isActive">
        <div class="title">定制开发培训</div>
        <div class="txt">正在建设中......</div>
    </div>
    <Footer_phone></Footer_phone>
  </div>
</template>
    <script>
import NavBar_phone from "@/components/NavBar_phone.vue";
import Footer_phone from "@/components/Footer_phone.vue";
import Au from "@/assets/img/Vutoliv_phone.png";
import logo1 from "@/assets/img/ARN_phone.png";
import ccc from "@/assets/img/Med.png";
export default {
  components: {
    NavBar_phone,
    Footer_phone,
  },
  data() {
    return {
      isActive: parseInt(this.$route.query.isActive),
      CustomerStories: [
        {
          label: "奥托立夫",
          EN_label: "AUTOLIV",
          txt: "通过ERP系统定制开发，实现了管理的标准化，资源的共享和流程的标准化。助力奥托立夫全球化扩张。",
          img_src: Au,
        },
        {
          label: "安庆环新集团",
          EN_label: "ARN",
          txt: "通过车间管理平台系统的定制开发，实现了生产过程条码跟踪，数据采集、监控与防错，减少了管理成本。",
          img_src: logo1,
        },
        {
          label: "苏州美创",
          EN_label: "MEICHUANG",
          txt: "通过QAD系统实施，实现全流程追溯，满足监管要求，通过了TUV13485质量管理体系认证和GSP审核",
          img_src: ccc,
        },
      ],
    };
  },
  methods: {
    abc() {
      console.log(123123123)
    }
  }
};
</script>
    <style lang="scss" scoped>
.container_IndustrySolutions_phone {
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    height: 10rem;
    width: 100%;
    background-image: url("@/assets/img/you-x-ventures-X8H8vPcelPk-unsplash (1).png");
    background-size: cover;
    background-position: 80% 20%;
    background-repeat: no-repeat;
    .mash {
      width: 100%;
      background-color: rgba(35, 37, 133, 0.8);
    //   opacity: 0.8;
      height: 100%;
      position: relative;
      .title {
        color: white;
        font-family: DingTalk JinBuTi;
        font-size: 2rem;
        text-align: center;
        padding-top: 6rem;
      }
    }
  }
  .container_tabbar {
    width: 100%;
    .tabbar {
      display: flex;
      font-size: 0.75rem;
      // color: #2F54EB;
      .tabbar_item {
        padding: 1rem;
        &.active {
          border-bottom: 2px solid #2f54eb;
          color: #2f54eb;
        }
      }
    }
  }
  .QAD{
    height: 30vh;
    display: flex;
    flex-direction: column;
    width: 80%;
    .title{
        font-family: DingTalk JinBuTi;
        font-size: 1.5rem;
        font-weight: normal;
        color: #2F54EB;
        margin-top: 1rem;
    }
    .txt{
        margin-top: 1rem;
    }
  }
}
</style>
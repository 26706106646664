<template>
    <div class="container_IndustrySolutions_phone">
      <div class="header">
        <div class="mash">
          <NavBar_phone></NavBar_phone>
          <div class="title">{{ $t("message.ShanghaiEsunSoftwareFirm") }}</div>
        </div>
      </div>
      <Card_phone>
        <template  v-slot:title>公司介绍</template>
        <template  v-slot:banner><img src="@/assets/img/gongsijieshao_phone.png" /></template>
        <template v-slot:body>
          <div class="CompanyProfile">
            <div class="head">上海奕盛软件有限公司</div>
            <div class="label">卓越创新</div>
            <div class="txt">自2000年创建以来,以坚韧的努力和追求卓越的精神不断创新，致力于为中国地区的制造商提供完整的企业管理及IT解决方案帮助打造高效企业迎接全球化发展带来的挑战，并以优良的技术和完美的服务，赢得众多外资与本地客户的信赖。我们高度关注汽车、生命科学、电子、食品饮料和消费品等垂直行业的发展并基于信息简单、自动化等原则协助该行业领域内的制造商</div>
            <div class="label">高效管理</div>
            <div class="txt">作为全球化之下的制造商追求的目标，是先进制造商标志之一，我们基于企业数据平台帮助制造商达成以下高效运营目标：</div>
            <ul>
              <li 
                v-for="(item, index) in CompanyProfile"
                :key="index"
                class="list">
                <img src="@/assets/img/juxin19.svg" />
                <div>{{ item }}</div>
              </li>
          </ul>
          </div>
        </template>
    </Card_phone>
    <Card_phone>
        <template  v-slot:title>关于我们</template>
        <template v-slot:body>
          <div class="AboutUs">
            <div id="container" style="width: 100%; height: 8rem;"></div>
            <div class="bottom">
              <div class="label">公司地点</div>
              <div class="txt">上海市闵行区浦江镇联航路1588号国家现代服务业软件产业基地SOHO研发楼第8幢</div>
              <div class="label">联系我们</div>
              <div class="txt">021-53015840</div>
              <div class="txt">sales@esun.sh.cn</div>
              <div class="txt">support@esun.sh.cn</div>
            </div>
          </div>
        </template>
    </Card_phone>
      <Footer_phone></Footer_phone>
    </div>
  </template>
      <script>
  import NavBar_phone from "@/components/NavBar_phone.vue";
  import Footer_phone from "@/components/Footer_phone.vue";
  import Card_phone from "@/components/Card_phone.vue";
 
  export default {
    components: {
      NavBar_phone,
      Footer_phone,
      Card_phone
    },
    data() {
      return {
        CompanyProfile: [
          "协同、合规与自动化供应链管理",
          "质量＝生命",
          "面向效率与合规的财务管理",
          "自动化业务流程管理",
        ],
      };
    },
    methods: {
      mapInit() {
        // 2. 创建地图示例
        let map = new window.BMapGL.Map("container");
        // 3. 设置中心点坐标
        let point = new window.BMapGL.Point(121.522701, 31.084073);
        let marker = new window.BMapGL.Marker(point); //将点转化成标注点
        map.addOverlay(marker); //将标注点添加到地图上
        // 4. 地图初始化，同时设置地图展示级别
        map.centerAndZoom(point, 12);
        // 5. 开启鼠标滚轮缩放
        map.enableScrollWheelZoom(true);
        let zoomCtrl = new window.BMapGL.ZoomControl(); // 添加缩放控件
        map.addControl(zoomCtrl);
        let cityCtrl = new window.BMapGL.CityListControl(); // 添加城市列表控件
        map.addControl(cityCtrl);
      },
    },
    mounted() {
      this.mapInit();
    },
  };
  </script>
      <style lang="scss" scoped>
  .container_IndustrySolutions_phone {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .header {
      height: 10rem;
      width: 100%;
      background-image: url("@/assets/img/you-x-ventures-X8H8vPcelPk-unsplash (2).png");
      background-size: cover;
      background-position: 55% 45%;
      background-repeat: no-repeat;
      .mash {
        width: 100%;
        background-color: rgba(35, 37, 133, 0.8);
        // opacity: 0.8;
        height: 100%;
        position: relative;
        .title {
          color: white;
          font-family: DingTalk JinBuTi;
          font-size: 1.5rem;
          text-align: center;
          padding-top: 6rem;
        }
      }
    }
    
  }
  </style>
export default {
    message: {
      login: '登录',
      Home:"首页",
      OverallPlan:"总体方案",
      IndustrySolutions:"行业方案",
      CustomerStories:"客户案例",
      TrainingServe:"培训服务",
      AboutUs:"关于我们",
      SiteNavigation:"网站导航",
      ContactUs:"联系我们",
      TEL:"电话",
      Email:"邮箱",
      ConsultationAndUnderstanding:"咨询了解",
      SUPPORT:"客户支持",
      JoinUs:"加入我们",
      TalentRecruitment:"人才招聘",
      ResumeSubmission:"简历投递",
      AutomobileIndustry:"汽车行业",
      LifeScienceIndustry:"生命科学行业",
      QADModuleTraining:"QAD模块培训",
      CustomDevelopmentTraining:"定制开发培训",
      ShanghaiEsunSoftwareFirm:"上海奕盛软件有限公司"
    }
  }
<template>
  <div>
    <div class="header">
      <div class="appear_1"><NavBar></NavBar></div>
      <div class="appear_2"><NavBar_mini></NavBar_mini></div>
      <div class="banner">
        <div class="left">
          <div class="main">
            <div class="title">
              <div>自动化+高效+智能</div>
              <div>为制造赋能</div>
            </div>
            <div class="spec">
              <div>Automation+Efficiency+Intelligence</div>
              <div>Empowering Manufacturing</div>
            </div>
          </div>
        </div>
        <div class="right">
          <img src="@/assets/img/banner.png" />
        </div>
      </div>
    </div>
    <div class="part_1"></div><!-- 空 -->
    <div class="appear_1"><Card>
      <template v-slot:title> 公司介绍 </template>
      <template v-slot:body>
        <div class="CompanyProfile">
          <div class="head">上海奕盛软件有限公司</div>
          <div class="foot">
            <img src="@/assets/img/juxing18.png" />
            <div class="right">
              <div class="tit">卓越创新</div>
              <p class="twofloor">
                自2000年创建以来,以坚韧的努力和追求卓越的精神不断创新，致力于为中国地区的制造商提供完整的企业管理及IT解决方案帮助打造高效企业迎接全球化发展带来的挑战，并以优良的技术和完美的服务，赢得众多外资与本地客户的信赖。我们高度关注汽车、生命科学、电子、食品饮料和消费品等垂直行业的发展并基于信息简单、自动化等原则协助该行业领域内的制造商
              </p>
              <div class="tit">高效管理</div>
              <div
                v-for="(item, index) in CompanyProfile"
                :key="index"
                class="list"
              >
                <img src="@/assets/img/juxin19.svg" />
                <div>{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card></div>
    <div class="appear_2"><Card>
      <template v-slot:title> 公司介绍 </template>
      <template v-slot:body>
        <div class="CompanyProfile">
          <img src="@/assets/img/juxing 18-1.png"/>
          <div class="head">上海奕盛软件有限公司</div>
          <div class="foot">
            <div class="right">
              <div class="tit">卓越创新</div>
              <p class="twofloor">
                自2000年创建以来,以坚韧的努力和追求卓越的精神不断创新，致力于为中国地区的制造商提供完整的企业管理及IT解决方案帮助打造高效企业迎接全球化发展带来的挑战，并以优良的技术和完美的服务，赢得众多外资与本地客户的信赖。我们高度关注汽车、生命科学、电子、食品饮料和消费品等垂直行业的发展并基于信息简单、自动化等原则协助该行业领域内的制造商
              </p>
              <div class="tit">高效管理</div>
              <div
                v-for="(item, index) in CompanyProfile"
                :key="index"
                class="list"
              >
                <img src="@/assets/img/juxin19.svg" />
                <div>{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card></div>
    <div class="part_2"></div><!-- 空 -->
    <Card>
      <template v-slot:title>总体方案</template>
      <template v-slot:body>
        <p>
          公司基于ERP核心，衍生后ERP时代打造企业数字化，智能化管理，构建一体化解决方案体系，整合软硬件协同的专业咨询服务，协助制造业建立企业级大数据，借助云计算、机器学习、数据湖、机器流程自动化RPA
          、IoT物联网等先进信息技术手段，走向智能工厂
        </p>
        <img src="@/assets/img/image.png" />
      </template>
    </Card>
    <div class="part_3"></div><!-- 空 -->
    <Card>
      <template v-slot:title>行业方案</template>
      <template v-slot:body>
        <div class="appear_1">
        <div class="IndustrySolutions">
          <div
            class="list"
            v-for="(item, index) in IndustrySolutions"
            :key="index"
          >
            <div class="left" style="position: relative;">
              <div class="label">{{ item.label }}</div>
              <p>{{ item.txt_1 }}</p>
              <p>{{ item.txt_2 }}</p>
              <div class="button"
              @click="$router.push({ path: '/IndustrySolutions', query: { isActive: index }})"
              style="position: absolute;bottom: 30px;">
                  <i class="el-icon-arrow-right"></i> 查看更多
              </div>
            </div>
            <div class="right">
              <img class="EIMG" :src="item.img_src" />
            </div>
          </div>
        </div>
        </div>
        <div class="appear_2">
        <div class="IndustrySolutions">
          <div
            class="list"
            v-for="(item, index) in IndustrySolutions"
            :key="index"
          >
            <div class="right">
              <img class="EIMG" :src="item.img_1" style="width: 100%;height: 166px;object-fit: cover;"/>
            </div>
            <div class="left">
              <div class="label">{{ item.label }}</div>
              <p>{{ item.txt_1 }}</p>
              <p>{{ item.txt_2 }}</p>
              <div class="button" @click="$router.push({ path: '/IndustrySolutions', query: { isActive: index }})">
                  <i class="el-icon-arrow-right"></i>查看更多
              </div>
            </div>
          </div>
        </div>
        </div>
      </template>
    </Card>
    <div class="part_4"></div><!-- 空 -->
    <Card>
      <template v-slot:title>客户案例</template>
      <template v-slot:body>
        <div class="CustomerStories">
          <div
            class="list"
            v-for="(item, index) in CustomerStories"
            :key="index"
            @click="$router.push('/CustomerStories')"
          >
            <div class="img">
              <img :src="item.img_src" />
            </div>
            <div class="tit">{{ item.label }}</div>
            <div class="EN_tit">{{ item.EN_label }}</div>
            <div class="txt">{{ item.txt }}</div>
            <div class="list_hover"></div> 
          </div>
        </div>
      </template>
    </Card>
    <div class="part_5"></div><!-- 空 -->
    <Card>
      <template v-slot:title>培训服务</template>
      <template v-slot:body>
        <div class="TrainingServe">
          <div class="list" @click="$router.push({ path: '/TrainingServe', query: { isActive: 0 }})">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <img src="@/assets/img/vector.svg" />
              <el-link class="more" style="color: #2f54eb"
                ><i class="el-icon-right"></i>查看更多</el-link
              >
            </div>
            <div class="tit">QAD模块培训</div>
            <p class="txt">QAD标准模块培训</p>
          </div>
          <div class="list" @click="$router.push({ path: '/TrainingServe', query: { isActive: 1 }})">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <img src="@/assets/img/vector.svg" />
              <el-link class="more" style="color: #2f54eb"
                ><i class="el-icon-right"></i>查看更多</el-link
              >
            </div>
            <div class="tit">定制开发培训</div>
            <p class="txt">开发语言培训</p>
          </div>
        </div>
      </template>
    </Card>
    <div class="part_6"></div><!-- 空 -->
    <Card>
      <template v-slot:title>关于我们</template>
      <template v-slot:body>
        <div id="container" class="map"></div>
        <div class="addr">
          <el-row style="margin-bottom: 20px">
            <el-col :span="4" style="text-align: center; font-weight: 600"
              >公司地点</el-col
            >
            <el-col :span="20" style="text-align: left"
              >上海市闵行区浦江镇联航路1588号国家现代服务业软件产业基地SOHO研发楼第8幢</el-col
            >
          </el-row>
          <el-row style="margin-bottom: 20px">
            <el-col :span="4" style="text-align: center; font-weight: 600"
              >联系我们</el-col
            >
            <el-col :span="20" style="text-align: left">021-53015840</el-col>
          </el-row>
          <el-row>
            <el-col :span="4" style="text-align: center; font-weight: 600"
              >&nbsp;</el-col
            >
            <el-col :span="20" style="text-align: left"
              >sales@esun.sh.cn support@esun.sh.cn</el-col
            >
          </el-row>
        </div>
      </template>
    </Card>
    <div class="part_7"></div><!-- 空 -->
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import NavBar_mini from "@/components/NavBar_mini.vue";
import Footer from "@/components/Footer.vue";
import Card from "@/components/Card.vue";
import car from "@/assets/img/juxin12.png";
import car_1 from "@/assets/img/juxin12-1.png"
import life from "@/assets/img/juxin13.png";
import life_1 from "@/assets/img/juxin13-1.png";
import Au from "@/assets/img/Au.png";
import logo1 from "@/assets/img/logo1.png";
import ccc from "@/assets/img/ccc.png";
export default {
  name: "HomeView",
  components: {
    NavBar,
    NavBar_mini,
    Card,
    Footer,
  },
  data() {
    return {
      CompanyProfile: [
        "协同、合规与自动化供应链管理",
        "质量＝生命",
        "面向效率与合规的财务管理",
        "自动化业务流程管理",
      ],
      IndustrySolutions: [
        {
          label: "汽车行业",
          txt_1:
            "随着宏观环境的变化，中国汽车产业正步入转型升级的重质量发展时代。在各项战略举措的支持下深化改革，市场环境被显著重塑。",
          txt_2:
            "汽车行业高管将数字化转型作为优先举措，40%的受访企业已从数字化转型中实现了显著的投资回报率，逾半数的受访者计划在来年大幅增加数字化转型方面的投资。在驱动因素方面，尽管生产和运营目标是推动数字化转型最重要的两大因素，而汽车行业则更多地受竞争压力和创新需求的推动。在推动数字化创新的职能部门中，信息技术（IT）部门高居首位，不过供应链、产品设计、市场和规划方面的数字化转型相对成熟，这也将继续成为未来投资的优先领域。",
          img_src: car,
          img_1: car_1,
        },
        {
          label: "生命科学",
          txt_1:
            "在过去几年中，中国国内医药消费增长迅猛，医药行业销售收入平均年增长达21.4%。受强劲的经济增长、不断加剧的城市化进程以及老龄化带来的就医需求等因素的驱动，中国将成为世界最大的医药市场之一。到2020年，中国的医药市场销售额预计达到2200亿美元，中国将成为仅次于美国的世界第二大医药市场。",
          img_src: life,
          img_1: life_1,
        },
      ],
      CustomerStories: [
        {
          label: "奥托立夫",
          EN_label: "AUTOLIV",
          txt: "通过ERP系统定制开发，实现了管理的标准化，资源的共享和流程的标准化。助力奥托立夫全球化扩张。",
          img_src: Au,
        },
        {
          label: "安庆环新集团",
          EN_label: "ARN",
          txt: "通过车间管理平台系统的定制开发，实现了生产过程条码跟踪，数据采集、监控与防错，减少了管理成本。",
          img_src: logo1,
        },
        {
          label: "苏州美创",
          EN_label: "MEICHUANG",
          txt: "通过QAD系统实施，实现全流程追溯，满足监管要求，通过了TUV13485质量管理体系认证和GSP审核",
          img_src: ccc,
        },
      ],
    };
  },
  methods: {
    mapInit() {
      // 2. 创建地图示例
      let map = new window.BMapGL.Map("container");
      // 3. 设置中心点坐标
      let point = new window.BMapGL.Point(121.522701, 31.084073);
      let marker = new window.BMapGL.Marker(point); //将点转化成标注点
      map.addOverlay(marker); //将标注点添加到地图上
      // 4. 地图初始化，同时设置地图展示级别
      map.centerAndZoom(point, 20);
      // 5. 开启鼠标滚轮缩放
      map.enableScrollWheelZoom(true);
      let zoomCtrl = new window.BMapGL.ZoomControl(); // 添加缩放控件
      map.addControl(zoomCtrl);
      let cityCtrl = new window.BMapGL.CityListControl(); // 添加城市列表控件
      map.addControl(cityCtrl);
    },
    jumpToMuch(){
      console.log("666")
      this.$router.push('/IndustrySolutions')
    }
  },
  mounted() {
    this.mapInit();
    console.log("可用宽" + document.documentElement.clientWidth + "屏幕宽" + window.screen.availWidth + "窗口宽" + window.innerWidth);
  },
};
</script>
<style lang="scss" scoped>
.header {
  background-color: #232585;
  position: relative;
  height: 928px;
  overflow: hidden;
  .banner {
    display: flex;
    margin: auto;
    width: 75rem;
    .left {
      width: 54%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      .main {
        .title {
          display: flex;
          flex-direction: column;
          font-family: DingTalk JinBuTi;
          font-size: 4.25rem;
          font-weight: normal;
          line-height: 6.25rem;
          letter-spacing: 5.67px;
        }
        .spec {
          font-family: DingTalk JinBuTi;
          font-size: 0.875rem;
          font-weight: normal;
          line-height: 2rem;
          letter-spacing: 0.24em;
        }
      }
    }
    .right {
      width: 46%;
      padding: 120px 0 0 20px;
      >img{
        width: 55rem;
        display: block;
      }
    }
  }
}
.appear_1{
  display: inline;
}
.appear_2{
  display: none;
}

.map{
  width: 100%;
  height: 22rem;
  margin-top: 30px;
}
//用于填补空隙
.part_1{
  padding: 100px;
}
.part_2{
  padding: 190px;
}
.part_3{
  padding: 260px;
}
.part_4{
  padding: 180px;
}
.part_5{
  padding: 50px;
}
.part_6{
  padding: 100px;
}
.part_7{
  padding: 125px;
}

@media (max-width: 1320px) {
  .header {
    background-color: #232585;
    position: relative;
    height: 594px;
    overflow: hidden;
    .banner {
      display: flex;
      margin: auto;
      width: 48.75rem;
      .left {
        width: 54%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        .main {
          .title {
            display: flex;
            flex-direction: column;
            font-family: DingTalk JinBuTi;
            font-size: 2.62rem;
            font-weight: normal;
            line-height: 3.85rem;
            letter-spacing: 5.67px;
          }
          .spec {
            font-family: DingTalk JinBuTi;
            font-size: 0.875rem;
            font-weight: normal;
            line-height: 2rem;
            letter-spacing: 0.24em;
          }
        }
      }
      .right {
        width: 46%;
        padding: 70px 0 0 20px;
        >img{
          width: 35rem;
          display: block;
        }
      }
    }
  }
  .appear_2{
    display: inline;
  }
  .appear_1{
    display: none;
  }

  .map{
    width: 100%;
    height: 15rem;
    margin-top: 30px;
  }
  //用于填补空隙
  .part_1{
    padding: 74px;
  }
  .part_2{
    padding: 110px;
  }
  .part_3{
    padding: 240px;
  }
  .part_4{
    padding: 190px;
  }
  .part_5{
    padding: 80px;
  }
  .part_6{
    padding: 80px;
  }
  .part_7{
    padding: 50px;
  }
}

@media (max-width: 840px) {
  .header {
    background-color: #232585;
    position: relative;
    height: 480px;
    overflow: hidden;
    .banner {
      display: flex;
      margin: auto;
      width: 48.75rem;
      .left {
        width: 54%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        .main {
          .title {
            display: flex;
            flex-direction: column;
            font-family: DingTalk JinBuTi;
            font-size: 2.54rem;
            font-weight: normal;
            line-height: 3.85rem;
            letter-spacing: 5.67px;
          }
          .spec {
            font-family: DingTalk JinBuTi;
            font-size: 0.875rem;
            font-weight: normal;
            line-height: 2rem;
            letter-spacing: 0.24em;
          }
        }
      }
      .right {
        width: 46%;
        padding: 50px 0 0 20px;
        >img{
          width: 35rem;
          display: block;
        }
      }
    }
  }
  .appear_2{
    display: inline;
  }
  .appear_1{
    display: none;
  }

  .map{
    width: 100%;
    height: 15rem;
    margin-top: 30px;
  }
  //用于填补空隙
  .part_1{
    padding: 35px;
  }
  .part_2{
    padding: 32px;
  }
  .part_3{
    padding: 155px;
  }
  .part_4{
    padding: 130px;
  }
  .part_5{
    padding: 30px;
  }
  .part_6{
    padding: 30px;
  }
  .part_7{
    padding: 30px;
  }
}
</style>

<template>
  <div>
    <div class="header">
      <div class="appear_1"><NavBar></NavBar></div>
      <div class="appear_2"><NavBar_mini></NavBar_mini></div>
      <div class="title">
        <div>{{ $t("message.CustomerStories") }}</div>
      </div>
    </div>
    <Card style="margin: 50px 0;">
      <template v-slot:body>
        <div class="CustomerStories">
          <div
            class="list"
            v-for="(item, index) in CustomerStories"
            :key="index"
          >
            <div class="img">
              <img :src="item.img_src" />
            </div>
            <div class="tit">{{ item.label }}</div>
            <div class="EN_tit">{{ item.EN_label }}</div>
            <div class="txt">{{ item.txt }}</div>
          </div>
        </div>
      </template>
    </Card>
    <Footer></Footer>
  </div>
</template>
  
  <script>
import NavBar from "@/components/NavBar.vue";
import NavBar_mini from "@/components/NavBar_mini.vue";
import Footer from "@/components/Footer.vue";
import Au from "@/assets/img/Au.png";
import logo1 from "@/assets/img/logo1.png";
import ccc from "@/assets/img/ccc.png";
import Card from "@/components/Card.vue";

export default {
  components: {
    NavBar,
    NavBar_mini,
    Footer,
    Card
  },
  data() {
    return {
        CustomerStories: [
        {
          label: "奥托立夫",
          EN_label: "AUTOLIV",
          txt: "通过ERP系统定制开发，实现了管理的标准化，资源的共享和流程的标准化。助力奥托立夫全球化扩张。",
          img_src: Au,
        },
        {
          label: "安庆环新集团",
          EN_label: "ARN",
          txt: "通过车间管理平台系统的定制开发，实现了生产过程条码跟踪，数据采集、监控与防错，减少了管理成本。",
          img_src: logo1,
        },
        {
          label: "苏州美创",
          EN_label: "MEICHUANG",
          txt: "通过QAD系统实施，实现全流程追溯，满足监管要求，通过了TUV13485质量管理体系认证和GSP审核",
          img_src: ccc,
        },
      ],
    };
  },
};
</script>
  
<style lang="scss" scoped>
  .header {
  background-image: url("@/assets/img/you-x-ventures-X8H8vPcelPk-unsplash.png");
  background-position-y: -155px;
  height: 327px;
  position: relative;
  width: 100%;
  .title {
    height: 100%;
    background-color: #232585;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 3rem;
    > div {
      font-family: DingTalk JinBuTi;
    }
  }
}
.appear_1{
  display: inline;
}
.appear_2{
  display: none;
}

@media (max-width: 1320px) {
  .appear_2{
    display: inline;
  }
  .appear_1{
    display: none;
  }
}
</style>
export default {
  message: {
    Home: 'Home',
    login: 'login',
    OverallPlan: 'OverallPlan',
    IndustrySolutions: 'IndustrySolutions',
    CustomerStories: 'CustomerStories',
    TrainingServe: 'TrainingServe',
    AboutUs: 'AboutUs',
    SiteNavigation: 'SiteNavigation',
    ContactUs: 'ContactUs',
    TEL: 'TEL',
    Email: 'Email',
    ConsultationAndUnderstanding: 'ConsultationAndUnderstanding',
    SUPPORT: 'SUPPORT',
    JoinUs: 'JoinUs',
    TalentRecruitment: 'TalentRecruitment',
    ResumeSubmission: 'ResumeSubmission',
    AutomobileIndustry: 'AutomobileIndustry',
    LifeScienceIndustry: 'LifeScienceIndustry',
    QADModuleTraining: 'QADModuleTraining',
    CustomDevelopmentTraining: 'CustomDevelopmentTraining'
  }
}
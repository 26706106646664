<template>
  <div class="container">
    <div class="logo">
      <img src="@/assets/img/esun_logo.svg">
    </div>
    <div class="menu">
      <div class="menu-item" v-for="(item,index) in navbar" :key="index" @click="$router.push(item.link)">
        <div>{{ item.label }}</div>
        <div class="borderBottom"></div>
      </div>
    </div>
    <div class="btn">
      <div @click="change">中/EN</div>
      <!-- <div style="border: 1px solid white;border-radius: 1px;">{{$t('message.login')}}</div> -->
    </div>
  </div>
</template>
<script>
export default {
  computed:{
    navbar(){
      return [
        {label:this.$t('message.Home'),link:'/'},
        {label:this.$t('message.OverallPlan'),link:'/OverallPlan'},
        {label:this.$t('message.IndustrySolutions'),link:'/IndustrySolutions'},
        {label:this.$t('message.CustomerStories'),link:'/CustomerStories'},
        {label:this.$t('message.TrainingServe'),link:'/TrainingServe'},
        {label:this.$t('message.AboutUs'),link:'/AboutUs'},
      ]
    }
  },
  data(){
    return{
    }
  },
  methods:{
    change(){
      if(this.$i18n.locale == 'en'){
		    this.$i18n.locale = 'zh'
        localStorage.setItem('lang', this.$i18n.locale)
	    }else{
		    this.$i18n.locale = 'en'
        localStorage.setItem('lang', this.$i18n.locale)
	    }
      this.$router.go(0)
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes borderBottom{
  0%{width: 0;}
  100%{width: 100%;}
}
.container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;
    position: fixed;
    background-color: rgb(35, 37, 133, 1);
    width: 100%;
    top: 0;
    z-index: 99;
    .menu{
      display: flex;
      width: 50%;
      color: white;
      justify-content: space-between;
      .menu-item{
        cursor: pointer;
        animation: 10s;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0.5rem;
        &:hover .borderBottom{
          animation: borderBottom 0.5s;
          animation-fill-mode:forwards;
        }
        .borderBottom{
          width: 0%;
          height: 1px;
          background-color: white;
        }
      }
    }
    .btn{
      display: flex;
      color: white;
      >div{
        padding: 5px 40px;
        cursor: pointer;
        }
    }
}
</style>